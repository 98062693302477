<template>
  <div class="page-container">
    <el-button type="primary" @click="openQuanSystem">打开 中国工农红军四渡赤水纪念馆</el-button>
  </div>
</template>

<script>
import {SchoolModel} from "@/model/exp/SchoolModel";
import {CommonModel} from "@/model/CommonModel";
import {loginExe, programAddScore} from "@/api/exp/TestApi";
import {msg_err, msg_success} from "@/utils/ele_component";
import {mapState} from "vuex";

export default {
  name: "teacherCddxExam",
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    return {
      tabShow:"doc"
    }
  },
  mounted() {

  },
  methods: {
    openQuanSystem(){
      window.open('https://www.720yun.com/t/5bakihpq7qh')
    }
  }
}
</script>

<style scoped>

</style>
